.Transparencia {
  color: #b600a6;
  text-align: justify;
  background: #46203a;
  background-color: #ffffff;
}

.Transparencia__Negro {
  margin-left: 10%;
  margin-right: 10%;
  color: #000000;
  text-align: justify;
  background: #46203a;
  background-color: #ffffff;
}

.Transparencia__title {
  color: #ffffff;
}
.Transparencia__title-2 {
  color: #ce788e;
}

.Transparencia__title,
.Transparencia__title-2 {
  font-weight: bolder;
  text-align: center;
}

/* .Home__coronavirus {
  width: 20%;
} */

.Transparencia__coronavirus {
  display: block;
  animation-name: Transparencia__coronavirus;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.hr {
  height: 15px;
  background: rgba(168, 8, 136, 1);
  margin-top: 30px;
  margin-bottom: 0px;
}
/* --------------- K E Y F R A M E S --------------- */

@keyframes Transparencia__coronavirus {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Transparencia__title {
    font-size: 40px;
    margin-top: 20px;
  }
  .Transparencia__title-2 {
    font-size: 30px;
  }
  .Content__grid img {
    display: grid;
    width: 62x !important;
  }
  .Transparencia .Grid__folder {
    display: grid;
    grid-template-columns: auto auto !important;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Content__grid img {
    width: 52px !important;
  }
  .Transparencia .Grid__folder {
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: auto auto !important;
  }
  .Content__grid,
  .Grid__four,
  .Grid__three {
    grid-template-columns: auto auto auto;
    gap: 20px;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Content__grid img {
    width: 42px !important;
  }
  .Transparencia .Grid__folder {
    margin-left: 15%;
    margin-right: 15%;
    display: grid;
    grid-template-columns: auto !important;
    gap: 70px !important;
    grid-template-columns: auto auto;
    gap: 20px;
  }
  .Content__grid,
  .Grid__four,
  .Grid__three {
    grid-template-columns: auto auto;
    gap: 20px;
  }
}
