.Footer {
  background-color: #340928 !important;
  color: #ffffff !important;
  text-align: center;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Footer {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .Footer div:nth-child(1) {
    text-align: right !important;
    width: 25%;
  }
  .Footer div:nth-child(2) {
    text-align: left !important;
    padding: 0 0 0 50px;
    width: 75%;
  }
  .Footer img {
    width: 35%;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Footer {
    display: row;
    grid-template-columns: auto;
    padding: 15px;
  }
  .Footer img {
    width: 40%;
    margin: 10px;
  }
}
