body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  text-align: justify !important;
}

.App__container {
  margin: auto;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.bottom-title {
  margin: 10px 0 15px 0 !important;
  padding: 0 0 20px 0 !important;
  border-bottom-width: 7px;
  border-bottom-style: dotted;
  border-bottom-color: rgba(168, 8, 136, 0.6);
  border-top: none;
}
/* --------------- C A R O U S E L --------------- */

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ba1678' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ba1678' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators [data-bs-target] {
  background-color: #ba1678 !important;
  height: 7px !important;
  border: 1px solid #ba1678 !important;
  border-radius: 10px !important;
  box-shadow: 3px 2px #000000 !important;
  width: 40px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Home__container,
  .Acuerdos__container,
  .Infografias__container,
  .Contingencia__container,
  .Posicionamiento__container,
  .Difusion__container,
  .Contacto__container,
  .Transparencia__container,
  .DatosP__container {
    width: 75%;
    /*border: 2px solid red;*/
    margin: auto;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Home__container
    .Acuerdos__container
    .Infografias__container
    .Contingencia__container
    .Posicionamiento__container
    .Difusion__container
    .Contacto__container
    .Transparencia__container
    .DatosP__container {
    width: 85%;
    border: 2px solid blue;
    margin: auto;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Home__container
    .Acuerdos__container
    .Infografias__container
    .Contingencia__container
    .Posicionamiento__container
    .Difusion__container
    .Contacto__container
    .Transparencia__container
    .DatosP__container {
    width: 90%;
    border: 2px solid orange;
    margin: auto;
  }
}
