.Contacto__box {
  height: 50px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
.Contacto {
  color: #b600a6;
  text-align: justify;
  background: #46203a;
  background-color: #ffffff;
}

.Contacto__title {
  color: #ffffff;
}
.Contacto__title-2 {
  color: #ce788e;
}

.Contacto__title,
.Contacto__title-2 {
  font-weight: bolder;
  text-align: center;
}

.btn-lg {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 2rem;
  --bs-btn-border-radius: 0.5rem;
}

/* .Home__coronavirus {
  width: 20%;
} */

.Contacto__coronavirus {
  display: block;
  animation-name: Contacto__coronavirus;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.hr {
  height: 15px;
  background: rgba(168, 8, 136, 1);
  margin-top: 30px;
  margin-bottom: 0px;
}
/* --------------- K E Y F R A M E S --------------- */

@keyframes Contacto__coronavirus {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Contacto__title {
    font-size: 40px;
    margin-top: 20px;
  }
  .Contacto__title-2 {
    font-size: 30px;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* mobil */
@media only screen and (max-width: 767px) {
}
