.Difusion {
  color: #b600a6;
  text-align: justify;
  background: #46203a;
  background-color: #ffffff;
}

.Difusion__title {
  color: #ffffff;
}
.Difusion__title-2 {
  color: #ce788e;
}

.Difusion__title,
.Difusion__title-2 {
  font-weight: bolder;
  text-align: center;
}

.hr {
  height: 15px;
  background: rgba(168, 8, 136, 1);
  margin-top: 30px;
  margin-bottom: 0px;
}
/* --------------- K E Y F R A M E S --------------- */

@keyframes Difusion__coronavirus {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Difusion__title {
    font-size: 40px;
    margin-top: 20px;
  }
  .Difusion__title-2 {
    font-size: 30px;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* mobil */
@media only screen and (max-width: 767px) {
}
