.Acuerdos__box {
  background-color: #790957;
  color: #ffffff;
  height: 60px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  text-align: justify;
}
.Acuerdos__body {
  background-color: #b10785;
  color: #ffffff;
}
