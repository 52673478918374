.Posicionamiento {
  color: #ffffff;
  text-align: justify;
  background: #46203a;
  background-color: linear-gradient(
    79deg,
    #46203a 15%,
    #6c2257 52%,
    #982a7a 100%
  );
}

.PosicionamientoPar {
  color: #ffffff;
  font-style: italic;
  text-align: justify;
  background: #46203a;
  background-color: linear-gradient(
    79deg,
    #46203a 15%,
    #6c2257 52%,
    #982a7a 100%
  );
}

.Posicionamiento__title {
  color: #ffffff;
}
.Posicionamiento__title-2 {
  color: #ce788e;
}

.Posicionamiento__title-3 {
  text-align: right;
}

.Posicionamiento__title,
.Posicionamiento__title-2 {
  font-weight: bolder;
  text-align: center;
}

/* .Posicionamiento__coronavirus {
  width: 20%;
} */

.Posicionamiento__persona {
  display: block;
  animation-name: Posicionamiento__coronavirus;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* --------------- K E Y F R A M E S --------------- */

@keyframes Posicionamiento__coronavirus {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* --------------- M E D I A     Q U E R I E S --------------- */

/* general */
@media only screen and (min-width: 1024px) {
  .Posicionamiento__title {
    font-size: 40px;
    margin-top: 20px;
  }
  .Posicionamiento__title-2 {
    font-size: 30px;
  }
  .Content__grid img {
    display: grid;
    width: 62x !important;
  }

  .Grid__folderPos {
    align-items: flex-end;
    display: grid;
    grid-template-columns: auto auto !important;
    gap: 10px !important;
  }
}

/* tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .Grid__folderPos {
    align-items: flex-end !important;
    display: grid;
    grid-template-columns: auto auto !important;
    gap: 10px !important;
  }
}

/* mobil */
@media only screen and (max-width: 767px) {
  .Grid__folderPosN {
    display: none;
  }
}
